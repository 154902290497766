<!--
 * @Autor: syq
 * @Date: 2021-07-12 19:23:20
-->
<template>
  <div id="information">
    <van-field
      v-model="username"
      label="我的姓名"
      v-show="user"
      placeholder="请输入姓名"
    />
    <van-field
      v-model="birthday"
      label="我的生日"
      v-show="userbirthday"
      placeholder="请输入生日"
    />
    <van-field
      v-model="sex"
      label="我的性别"
      v-show="usersex"
      placeholder="请输入性别"
    />
    <div class="quit">
      <button class="btn">保存</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      birthday: "",
      sex: "",
    };
  },
};
</script>

<style lang="less">
#information {
  .quit {
    display: flex;
    align-items: center;
    margin-top: 50px;
    .btn {
      width: 96%;
      border: 0;
      margin: 0 auto;
      font-size: 16px;
      height: 45px;
      line-height: 20px;
      border-radius: 7px;
      color: #fff;
      background-color: rgba(245, 108, 108, 0.8);
    }
  }
}
</style>
